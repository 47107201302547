.architecture-menu.active {
    background: var(--primColor);
    color: #fff;
    border-radius: 25px;
}
.architecture-menu {
    color: var(--primColor);
    font-weight: 500;
}
.none {
    display: none;
}
