.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

:root {
    --primColor: #fb7032;
}

html {
    font-family: 'Poppins', sans-serif !important;
    scroll-behavior: smooth;
}

.customContainer {
    padding-left: 30px !important;
    padding-right: 30px !important;
    width: 100% !important;
}

.primButton button {
    height: 50px;
    font-weight: 500;
    background-color: var(--primColor);
    color: #fff;
    border: 1px solid #fd8550;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 25px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.primButton:hover {
    color: var(--primColor);
    background: #fff;
}

.primButton:focus {
    box-shadow: none;
}

.secButton button {
    height: 50px;
    font-weight: 500;
    background-color: #fff;
    color: var(--primColor);
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 25px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.secButton:hover {
    color: #fff;
    background: var(--primColor);
}

.secButton:focus {
    box-shadow: none;
}

.alTitle {
    font-size: 40px;
    font-weight: 700;
    color: #484848;
    margin-bottom: 60px;
    text-align: center;
    font-family: 'Poppins';
}

.alTitle span {
    color: white;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #000;
}

.alBanner {
    width: 100%;
    height: 768px;
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
}

.alBanner::before {
    width: 100%;
    height: 100%;
    background: url(./assets/markdown/images/large_back.png);
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    z-index: -1;
    background-size: cover;
    background-position: bottom left;
}

.alBanner .albMain {
    display: flex;
    justify-content: space-between;
    height: 100%;
    font-family: 'Poppins';
}

.alBanner .albMain .albLt {
    width: 60%;
    text-align: left;
    margin-top: 0px;
}

.alBanner .albMain .albLt h6 {
    font-size: 16px;
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 5px;
    margin-top: 0;
    letter-spacing: 1px;
    font-family: 'Poppins';
    color: #434343;
}

.alBanner .albMain .albLt h2 {
    font-size: 70px;
    font-weight: 700;
    color: #414141;
}

.alBanner .albMain .albLt h1 {
    font-size: 55px;
    letter-spacing: 1px;
    font-family: 'Yeseva One', cursive;
    color: #fff;
    margin-top: 0;
    margin-bottom: 30px;
}

.alBanner .albMain .albLt h1 span {
    display: block;
}

.alBanner .albMain .albRt {
    width: 40%;
    margin-right: 60px;
    margin-top: 10px;
}

.alBanner .albMain .albRt img {
    width: 100%;
    height: auto;
}

.archDiagramCnt,
.versionHistory {
    margin-top: 150px;
}

.archDiagramCnt ul.nav {
    position: sticky;
    top: 0;
    background: #fff;
    padding: 10px 0;
}

.archDiagramCnt ul.nav li .nav-link {
    color: var(--primColor);
    font-weight: 500;
}

.archDiagramCnt ul.nav li .nav-link.active {
    background: var(--primColor);
    color: #fff;
    border-radius: 25px;
}

.versionHistory .nav .nav-link {
    background: #fff;
    color: #333;
    font-weight: 600;
    border-radius: 0;
    border-left: 2px solid #fff;
}

.versionHistory .nav .nav-link.active {
    background: #fff;
    color: var(--primColor);
    border-left: 2px solid var(--primColor);
}

.versionHistory .nav.nav-pills {
    position: sticky;
    top: 0;
    background: #fff;
    padding: 10px 0;
}

footer {
    background: #333333;
    color: #fff;
    text-align: center;
    padding: 10px;
    margin-top: 60px;
}

.sftWrpr .updtHdr {
    font-weight: normal;
    margin-bottom: 40px;
}

.sftWrpr .updtList span {
    color: #e14500;
    margin-right: 10px;
    font-weight: 600;
    line-height: 25px;
}

.sftWrpr h2 {
    font-size: 30px;
    margin-bottom: 15px;
}

.commActBox {
    margin: 15px 0;
}

.commActBox .MuiFormControl-root {
    width: 100%;
}

.commActBox .btnSearch {
    width: max-content;
    min-width: 150px;
    float: unset;
    margin: 0;
}

.commActBox .MuiOutlinedInput-root {
    width: 100%;
}

.MuiTypography-root {
    font-family: 'Poppins' !important;
    font-weight: normal !important;
    text-decoration: none;
}

.layout .MuiPaper-root.MuiDrawer-paper .subLinks .MuiListItem-button a {
    text-decoration: none;
}

.customInput .MuiOutlinedInput-notchedOutline,
.customSelect .MuiOutlinedInput-notchedOutline,
.customDatepicker .MuiOutlinedInput-notchedOutline {
    top: 0 !important;
}

.customInput .MuiInputLabel-outlined,
.customSelect .MuiInputLabel-outlined,
.customDatepicker .MuiInputLabel-outlined {
    background: white;
    padding-left: 6px !important;
    padding-right: 6px !important;
}

.customInput .MuiTextField-root {
    width: 100%;
}

.actionButton .primButton,
.actionButton .secButton {
    margin-left: 5px;
    margin-right: 5px;
}

.MuiSwitch-colorSecondary.Mui-checked {
    color: #fb7032 !important;
}

.docContainer {
    border: 1px solid rgb(183, 183, 183);
    padding: 20px;
    border-radius: 10px;
    overflow: scroll;
    width: 100%;
    max-height: calc(100vh - 120px);
}

.docContainer h1 {
    font-size: 24px;
}

.docContainer h2 {
    font-size: 22px;
}

.docContainer h3 {
    font-size: 21px;
}

.docContainer h4 {
    font-size: 20px;
}

.docContainer p,
.docContainer ul,
.docContainer ol,
.docContainer li {
    font-size: 15px;
}

.docContainer pre {
    background: rgb(255, 240, 223);
    padding: 10px;
    border-radius: 10px;
    margin: 10px 0;
}

.docContainer .table-wrapper {
    overflow: auto;
    margin: 20px 0;
}

.docContainer .table-wrapper table,
.docContainer table {
    font-size: 14px;
}

.docContainer .table-wrapper table,
.docContainer .table-wrapper table th,
.docContainer .table-wrapper table td,
.docContainer table,
.docContainer table th,
.docContainer table td {
    border: 1px solid black;
}

.docContainer .table-wrapper table th,
.docContainer .table-wrapper table td,
.docContainer table,
.docContainer table th,
.docContainer table td {
    padding: 10px;
}
