:root {
    --primColor: #fb7032;
}

html {
    font-family: 'Poppins', sans-serif !important;
    scroll-behavior: smooth;
}

.customContainer {
    padding-left: 30px !important;
    padding-right: 30px !important;
    width: 100% !important;
}

/* .primButton {
  height: 50px;
  font-weight: 500;
  background-color: var(--primColor);
  color: #fff;
  border: 1px solid #fd8550;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 25px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
} */

.primButton:hover {
    color: var(--primColor);
    background: #fff;
}

.primButton:focus {
    box-shadow: none;
}

/* .secButton {
  height: 50px;
  font-weight: 500;
  background-color: #fff;
  color: var(--primColor);
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 25px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
} */

.secButton:hover {
    color: #fff;
    background: var(--primColor);
}

.secButton:focus {
    box-shadow: none;
}

.alTitle {
    font-size: 25px;
    font-weight: 700;
    color: #484848;
    margin-bottom: 40px;
    text-align: center;
}

.alBanner {
    width: 100%;
    height: 768px;
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
}

.alBanner .albMain {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.alBanner .albMain .albLt {
    width: 60%;
    text-align: left;
    margin-top: 0px;
}

.alBanner .albMain .albLt h1 {
    font-size: 55px;
    letter-spacing: 1px;
    font-family: 'Yeseva One', cursive;
    color: #fff;
    margin-top: 0;
    margin-bottom: 30px;
}

.alBanner .albMain .albLt h1 span {
    display: block;
}

.alBanner .albMain .albLt .actBtn {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    margin-top: 40px;
    max-width: 500px;
}

.alBanner .albMain .albLt .actBtn .cstmBtn {
    background: white;
    width: 100%;
    height: 50px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border: 1px solid #f87945;
    color: #f87945;
}

.alBanner .albMain .albLt .actBtn .cstmBtn a {
    color: #f87945;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.alBanner .albMain .albLt .actBtn .cstmBtn .dropdown-menu a {
    color: black;
}

.alBanner .albMain .albRt {
    width: 40%;
    margin-right: 60px;
    margin-top: -30px;
}

.alBanner .albMain .albRt img {
    width: 100%;
    height: auto;
}

.archDiagramCnt,
.versionHistory {
    margin-top: 60px;
}

.archDiagramCnt .navbar {
    border-radius: 100px;
    padding: 20px 10px;
}

.archDiagramCnt .navbar .navbar-nav {
    width: 100%;
    font-family: 'Poppins';
}

.archDiagramCnt .navbar .navbar-nav a {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    padding-left: 40px;
    padding-right: 40px;
    margin: 0 12px;
    border: 1px solid #484848;
    font-weight: 400;
}

.archDiagramCnt .navbar .navbar-nav a.active {
    background: white;
    color: #f87945;
    border: 1px solid #f87945;
}

.archDiagramCnt ul.nav {
    position: sticky;
    top: 0;
    background: #fff;
    padding: 10px 0;
}

.archDiagramCnt ul.nav {
    position: sticky;
    top: 0;
    background: #fff;
    padding: 10px 0;
}

.archDiagramCnt ul.nav li .nav-link.active {
    background: var(--primColor);
    color: #fff;
    border-radius: 25px;
}

.versionHistory .nav .nav-link {
    background: #fff;
    color: #333;
    font-weight: 600;
    border-radius: 0;
    border-left: 2px solid #fff;
}

.versionHistory .nav .nav-link.active {
    background: #fff;
    color: var(--primColor);
    border-left: 2px solid var(--primColor);
}

.versionHistory .nav.nav-pills {
    position: sticky;
    top: 0;
    background: #fff;
    padding: 10px 0;
}

footer {
    background: #333333;
    color: #fff;
    text-align: center;
    padding: 10px;
    margin-top: 60px;
}

.sftWrpr .updtHdr {
    font-weight: normal;
}

.sftWrpr .updtList span {
    color: #e14500;
    margin-right: 10px;
    font-weight: 600;
    line-height: 25px;
}
