.text-align {
    text-align: left;
}
.none {
    display: none;
}

.versionHistory .nav .nav-link.rdrVrLnk {
    display: flex;
    align-items: center;
    color: #1a76f8;
    font-weight: 300;
}

.hmVrsnHstry.versionHistory .nav.nav-pills {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 10px;
    z-index: 999;
}

.hmVrsnHstry.versionHistory .nav a.nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    padding-left: 40px;
    padding-right: 40px;
    margin: 10px 12px;
    border: 1px solid #484848;
    font-weight: 400;
}

.hmVrsnHstry.versionHistory .nav .nav-link.active {
    background: white;
    color: #f87945;
    border: 1px solid #f87945;
}

.hmVrsnHstry.versionHistory .tab-pane,
.versionHistory.allVrsnPage .tab-pane {
    position: relative;
    padding: 30px;
    background: whitesmoke;
    border-radius: 17px;
}

.hmVrsnHstry.versionHistory .dwldVrsnBtn,
.versionHistory.allVrsnPage .dwldVrsnBtn {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
    width: max-content;
    padding: 10px 20px;
    border: 1px solid black;
    border-radius: 100px;
    position: absolute;
    right: 18px;
    top: 18px;
    z-index: 99;
}

.versionHistory.allVrsnPage .row {
    align-items: stretch;
}

.versionHistory.allVrsnPage .tab-content,
.versionHistory.allVrsnPage .tab-pane {
    height: 100%;
}
