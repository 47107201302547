.main-menu.active {
    background: var(--primColor);
    color: #fff;
    border-radius: 25px;
}
.main-menu {
    color: var(--primColor);
    font-weight: 500;
    font-style: normal;
}
.none {
    display: none;
}

.mnHdrNavCntnr {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #ff8a55 !important; */
    font-family: 'Poppins';
    color: black;
}

.mnHdrNavCntnr.fdNav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: transparent !important;
    z-index: 100000;
    padding-top: 15px;
    padding-bottom: 15px;
    height: 72px;
}

.mnHdrNavCntnr.fdNav .rtNavCntr a {
    color: rgb(38, 38, 38);
}

.mnHdrNavCntnr .logoCnt {
    max-width: 220px;
}

.mnHdrNavCntnr .logoCnt img {
    width: 100%;
}

.mnHdrNavCntnr .rtNavCntr a {
    color: black;
    text-decoration: none;
    font-weight: 400;
}

.mnHdrNavCntnr .rtNavCntr .navbar-nav {
    margin-left: 25px;
}

@media (max-width: 500px) {
    .mnHdrNavCntnr > div {
        flex-direction: column;
    }

    .rtNavCntr {
        flex-direction: column;
        justify-content: center !important;
        gap: 8px;
        padding: 16px 0;
    }

    .rtNavCntr * {
        margin: 0 !important;
        padding: 0 !important;
    }

    .mnHdrNavCntnr.fdNav {
        position: relative;
        height: auto;
    }
}
