.dataAnaltyics .statusHdr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 15px;
    background-color: #f8794552;
    border-radius: 0;
}

.dataAnaltyics .statusBnnr {
    padding: 12px 15px;
    background: #f8794514;
    min-height: 180px;
    display: flex;
    align-items: center;
}

.dataAnaltyics .statusBnnr .text-sm {
    font-size: 21px;
    color: #4d4d4d;
    font-weight: 400;
    display: block;
}

.dataAnaltyics .statusBnnr h4 {
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
}

.dataAnaltyics .statusHdr h4 {
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
}

.dataAnaltyics .dataAlysCard .dataAlyscardbdy .value h4 {
    font-size: 15px;
    color: #929292;
    background: #fff;
    font-weight: 500;
    padding: 0px 15px;
}

.dataAnaltyics .dataAlysCard .dataAlyscardbdy .value::before {
    content: '';
    position: absolute;
    top: 10px;
    height: 1px;
    border: 1px dashed #cecece;
    width: 100%;
    z-index: -1;
}

.dataAnaltyics .dataAlysCard .dataAlyscardbdy .value {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
}

.dataAnaltyics .dataAlysCard .dataAlyscardbdy .tit h5 {
    font-size: 16px;
    color: #ff8e00;
    font-weight: 500;
}

.dataAnaltyics .dataAlysCard .dataAlyscardbdy .tit h4 {
    font-size: 22px;
    color: #373737;
    font-weight: 500;
}

.dataAnaltyics .dataAlysCard .dataAlyscardbdy .tit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.dataAnaltyics .dataAlysCard {
    margin-top: 25px;
}

.dataAnaltyics .dataAlysCard .mainTit {
    margin-top: 50px;
    margin-bottom: 35px;
}

.dataAnaltyics .dataAlysCard .mainTit h4 {
    font-size: 26px;
    color: #373737;
    font-weight: 500;
}

.dataAnaltyics .dataAlysCard .reportedCard .reportedBdy {
    margin-bottom: 25px;
}

.dataAnaltyics .dataAlysCard .reportedCard .reportedBdy p {
    font-size: 16px;
    font-weight: 400;
    color: #ccc;
}

.dataAnaltyics .dataAlysCard .reportedCard .reportedBdy h4 {
    font-size: 20px;
    font-weight: 700;
    color: #000;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
}

.dataAnaltyics .dataAlysCard .dataAlyscardbdy {
    padding: 15px;
    box-shadow: 0px 4px 10px 0px #0000001c;
    border-radius: 4px;
    margin-bottom: 25px;
}
