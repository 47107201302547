.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 400px;
}

.mnLgnVwWrpr {
    box-shadow: 0 0 20px #00000011;
    padding: 40px 30px;
    font-family: 'Poppins';
}

.mnLgnVwWrpr .form-control {
    height: 50px;
    font-size: 15px;
}

.mnLgnVwWrpr button.btn-primary {
    border-radius: 100px;
    background: #fb7032;
    border: none;
    font-size: 15px;
    height: 50px;
}

.mnLgnVwWrpr .text-muted {
    color: red !important;
}

.loginCnt .customInput {
    margin-bottom: 25px;
}

.loginCnt .customInput .MuiTextField-root {
    width: 100%;
}

.loginCnt .loginTitle h2 {
    font-size: 22px;
    text-align: center;
    margin-bottom: 30px;
}
